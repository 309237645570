export const CREDENTIALS_VERIFICATION_QUERY = `credentialsVerifications {  
    credentialsVerifications{
      data {
        id
        attributes {
            label
            title
            description
        }
      }
    }
  }`;
