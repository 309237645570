import { Component, Vue } from "vue-property-decorator";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { commonService } from "@/providers/services/common-service";
import { appConfigService } from "@/providers/services/app/app-config";
import { filtersData } from "@/models/common-interface/common.interface";
import { PEER_FRONT_FACING_LISTING_QUERY } from "@/graphql/peer-front-facing-listing-query";
import { gql } from "graphql-tag";
@Component({
  name: "peer-listing",
  components: {
    AppLoaderComponent,
  },
})
export default class PeerListingComponent extends Vue {
  public FilterByCertificate = [
    { id: 1, name: "CPRS" },
    { id: 2, name: "CYAPSS" },
    { id: 3, name: "CFSS" },
  ];
  public isLoading = false;
  public regionList: any[] = [];
  public publicFacingList: any[] = [];
  public page = 1;
  public S3Path = "";
  public filterDetails: filtersData = {} as filtersData;
  public offset = 0;
  public limit = 8;
  public publicFacingListTotalCount = "";
  public searchNameGet = "";
  public regionSelected: any = [];
  public certificateSelected: any = [];
  public peersCertificateSelected = [];
  public selectedCertificate = "";
  public filterRegionGetName: any = [];
  public selectedRegionList: any = [];
  public peerListingQuery: any = [];
  public async created() {
    this.isLoading = true;
    this.S3Path = appConfigService.getS3FilesPathUrl();
    commonService.peersListingRegion().then((result) => {
      this.regionList = result.data.regions;
    });
    this.$apollo
      .query({
        query: gql`query ${PEER_FRONT_FACING_LISTING_QUERY}`,
      })
      .then((result) => {
        this.peerListingQuery = result.data.peerFrontFacingListings.data;
      });
    await this.publicFacingListData();
  }
  public publicFacingListData() {
    this.filterDetails.limit = this.limit;
    this.filterDetails.offset = this.offset;
    this.filterDetails.certificates = this.selectedCertificate;
    this.filterDetails.regions = this.selectedRegionList;
    commonService.publicFacilgList({ ...this.filterDetails }).then((result) => {
      this.isLoading = false;
      this.publicFacingList = result.data.peers;
      this.publicFacingListTotalCount = result.data.totalCount;
    });
  }

  public pageLoadContent(page: number) {
    this.isLoading = true;
    this.page = page;
    this.offset = (this.page - 1) * this.limit;
    this.filterDetails.offset = this.offset;
    this.publicFacingListData();
  }
  public searchListData() {
    if (this.searchNameGet.length == 0) {
      this.isLoading = true;
    }
    this.filterDetails.text = this.searchNameGet;
    this.offset = 0;
    this.publicFacingListData();
  }
  public resetPublicList() {
    this.isLoading = true;
    this.offset = 0;
    this.limit = 8;
    this.filterDetails.text = "";
    this.searchNameGet = "";
    this.regionSelected = [];
    this.certificateSelected = [];
    this.selectedCertificate = "";
    this.selectedRegionList = "";
    this.filterRegionGetName = [];
    this.peersCertificateSelected = [];
    this.publicFacingListData();
  }
  public onClearFilterRegion() {
    this.regionSelected = [];
    this.selectedRegionList = this.regionSelected;
    (this.$refs.dropdown as any).hide();
    this.isLoading = true;
    this.filterRegionGetName = [];
    this.publicFacingListData();
  }
  public filterRegionApply() {
    this.selectedRegionList = this.regionSelected;
    (this.$refs.dropdown as any).hide();
    this.regionNameGet();
    this.offset = 0;
    this.isLoading = true;

    this.publicFacingListData();
  }
  public onClearFilterCertificate() {
    this.certificateSelected = [];
    this.selectedCertificate = "";
    (this.$refs.dropdownCertificate as any).hide();
    this.isLoading = true;
    this.peersCertificateSelected = [];
    this.publicFacingListData();
  }
  public filterCertificateApply() {
    this.peersCertificateSelected = this.certificateSelected;
    this.selectedCertificate = this.peersCertificateSelected.join(",");
    (this.$refs.dropdownCertificate as any).hide();
    this.offset = 0;
    this.isLoading = true;
    this.publicFacingListData();
  }

  public regionNameGet() {
    this.filterRegionGetName = this.regionList.filter((person: any) =>
      this.regionSelected.includes(person.id)
    );
  }
  public onClearChip(index: any, filterIdentify: any) {
    this.isLoading = true;
    this.offset = 0;
    if (filterIdentify == "region") {
      this.peersCertificateSelected.splice(index, 1);
      this.selectedCertificate = this.peersCertificateSelected.join(",");
    } else {
      const chipClearFilter = this.selectedRegionList.filter(function (
        value: any
      ) {
        return value !== filterIdentify;
      });
      this.regionSelected = chipClearFilter;
      this.selectedRegionList = this.regionSelected;
      this.regionNameGet();
    }

    this.publicFacingListData();
  }
}
