import Vue from "vue";
import VueRouter from "vue-router";
import BaseLayout from "@/layouts/base-layout/base-layout.vue";
import HomePage from "@/layouts/home-page/home-page.vue";
import AboutUsComponent from "@/components/about-us/about-us.component.vue";
import AgenciesRegisterComponent from "@/components/agency-register/agencies-register.component.vue";
import AgenciesListingComponent from "@/components/agency-listings/agency-listings.component.vue";
import AgencySubmissionComponent from "@/components/agency-submission/agency-submission.component.vue";
import AgenciesViewComponent from "@/components/agency-view/agency-view.component.vue";
import PeerFrontFacingListingComponent from "@/components/peer-front-facing-listing/peer-front-facing-listing.component.vue";
import PeerListingComponent from "@/components/peers-listing/peers-listing.component.vue";
import HelpComponent from "@/components/help/help-info.component.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "home",
    component: BaseLayout,
    children: [
      {
        path: "/home",
        name: "home-page",
        component: HomePage,
      },
      {
        path: "/about-peer-grant",
        name: "about-peer-grant",
        component: AboutUsComponent,
      },
      {
        path: "/agency-register",
        name: "agency-register",
        component: AgenciesRegisterComponent,
      },
      {
        path: "/agency-listing",
        name: "agency-listing",
        component: AgenciesListingComponent,
      },
      {
        path: "/agency/form/submitted",
        name: "agency-submission",
        component: AgencySubmissionComponent,
      },
      {
        path: "/agency/view/:id",
        name: "agency-view",
        component: AgenciesViewComponent,
      },
      {
        path: "/peer-specialists",
        name: "peer-specialists",
        component: PeerFrontFacingListingComponent,
      },
      {
        path: "/peers-listing",
        name: "peers-listing",
        component: PeerListingComponent,
      },
      {
        path: "/help",
        name: "help",
        component: HelpComponent,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
