import { AppConfigModel } from "@/models/app-config/app-config.interface";

export class AppConfigService {
  private static INSTANCE = new AppConfigService();

  private APP_CONFIG = "APP_CONFIG";

  static get instance() {
    return this.INSTANCE;
  }

  public getApiUrl() {
    const appConfig: AppConfigModel = this.getAppConfig();
    return appConfig ? appConfig.api_endpoint_url : process.env.VUE_APP_API_URL;
  }

  public getStrapiApiUrl() {
    const appConfig: AppConfigModel = this.getAppConfig();
    return appConfig
      ? appConfig.strapi_api_url
      : process.env.VUE_APP_STRAPI_API_URL;
  }

  public getStrapiWithGraphqlApiUrl() {
    const appConfig: AppConfigModel = this.getAppConfig();
    return appConfig
      ? appConfig.strapi_graphql_api_url
      : process.env.VUE_APP_GRAPHQL_URL;
  }

  public getCPRSUrl() {
    const appConfig: AppConfigModel = this.getAppConfig();
    return appConfig ? appConfig.cprs_url : process.env.VUE_APP_CPRS_URL;
  }

  public getCYAPSSUrl() {
    const appConfig: AppConfigModel = this.getAppConfig();
    return appConfig ? appConfig.cyapss_url : process.env.VUE_APP_CYAPSS_URL;
  }

  public getCFSSUrl() {
    const appConfig: AppConfigModel = this.getAppConfig();
    return appConfig ? appConfig.cfss_url : process.env.VUE_APP_CFSS_URL;
  }

  public getEnvironmentType() {
    const appConfig: AppConfigModel = this.getAppConfig();
    return appConfig ? appConfig.environment : process.env.VUE_APP_ENVIRONMENT;
  }

  public getClientId() {
    const appConfig: AppConfigModel = this.getAppConfig();
    return appConfig ? appConfig.client_id : process.env.VUE_APP_CLIENT_ID;
  }

  public getClientKey() {
    const appConfig: AppConfigModel = this.getAppConfig();
    return appConfig ? appConfig.client_key : process.env.VUE_APP_CLIENT_KEY;
  }

  private getAppConfig() {
    let appConfigAsJson = null;
    const appConfigAsString: string | null = localStorage.getItem(
      this.APP_CONFIG
    );
    if (appConfigAsString) {
      appConfigAsJson = JSON.parse(appConfigAsString);
    }
    return appConfigAsJson;
  }
  public getS3FilesPathUrl() {
    const appConfig: AppConfigModel = this.getAppConfig();
    return appConfig
      ? appConfig.s3_files_path
      : process.env.VUE_APP_S3_FILES_PATH;
  }
}
export const appConfigService = AppConfigService.instance;
