import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { utils } from "@/utils/utils";
import { commonService } from "@/providers/services/common-service";
import { appConfigService } from "@/providers/services/app/app-config";
import { peerSpecialistsApi } from "@/providers/apis/peer-specialists";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { CREDENTIALS_VERIFICATION_QUERY } from "@/graphql/credentials-verification-query";
import {
  PeerFrontFacingListingData,
  filters,
} from "@/models/peer-front-facing-listing/peer-front-facing-listing.interface";
import moment from "moment";

@Component({
  name: "peer-listing",
  components: {
    AppLoaderComponent,
  },
})
export default class PeerFrontFacingListingComponent extends Vue {
  public peerSpecialitsData: PeerFrontFacingListingData[] = [];
  public filterDetails: filters = {} as filters;
  public isSearchData = false;
  public isLoading = false;
  public isDataEmpty!: boolean;
  public offset = 0;
  public limit = 20;
  public page = 1;
  public currentPage = 1;
  public totalItem = 0;
  public searchByFirstName = "";
  public searchByLastName = "";
  public errorMessage = "";
  public downloadFullSearchList: any;
  public filteredData: any;
  public json_data: any = [];
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
  };
  public tableFields = [
    { key: "firstName", label: "First Name", sortable: false },
    { key: "lastName", label: "	Last Name", sortable: false },
    {
      key: "programFullName",
      label: "Program Name",
      sortable: false,
      thClass: "program-name pointer-events",
      tdClass: "program-name-td",
    },
    {
      key: "modifiedRegion",
      label: "Region",
      sortable: false,
      thClass: "region-list pointer-events",
      tdClass: "regionList",
    },
    {
      key: "orginalCertificateDate",
      label: "Certification Date",
      sortable: false,
      thClass: "application_status pointer-events",
      tdClass: "Awarded-date-status",
    },
    {
      key: "expiryDate",
      label: "Expiration Date",
      sortable: false,
      thClass: "expiry_date pointer-events",
      tdClass: "expiryDate",
    },
  ];
  public dateBuilder: any;
  public regionListDropdown: any[] = [];
  public credentialRegionSelected: any[] = [];
  public selectedRegionListCredential: any[] = [];
  public credentialDropdown = true;
  public credentialRegionMenuList = true;
  public filterRegionGetName: any[] = [];
  public async created() {
    this.isLoading = true;
    this.dateBuilder = moment(new Date()).format("DDMMYYYY");
    commonService
      .logInWithCredential(
        appConfigService.getClientId(),
        appConfigService.getClientKey(),
        "anonymous"
      )
      .then((data) => {
        this.isLoading = false;
        localStorage.setItem("anonymousToken", data.access_token);
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
    commonService.peersListingRegion().then((result) => {
      this.regionListDropdown = result.data.regions;
    });

    await this.$apollo
      .query({
        query: gql`query ${CREDENTIALS_VERIFICATION_QUERY}`,
      })
      .then((result) => {
        this.peerSpecialitsData = result.data.credentialsVerifications.data;
      });
  }
  public regionMapNameget() {
    this.filteredData.forEach((data: any) => {
      this.regionListDropdown.forEach((region) => {
        if (region.id == data.region) {
          data["modifiedRegion"] = region.name;
        }
      });
    });
  }
  public async getPeerLists() {
    this.isLoading = true;
    this.filterDetails.regions = this.selectedRegionListCredential;
    this.filterDetails.limit = this.limit;
    this.filterDetails.offset = this.offset;
    this.filterDetails.firstName = this.searchByFirstName.toLowerCase();
    this.filterDetails.lastName = this.searchByLastName.toLowerCase();

    if (
      this.searchByLastName.length > 0 ||
      this.searchByFirstName.length > 0 ||
      this.filterRegionGetName.length > 0
    ) {
      await peerSpecialistsApi
        .getPeerSpecialist(this.filterDetails)
        .then((data) => {
          this.filteredData = data.peers;
          this.regionMapNameget();
          this.totalItem = data.totalCount;
          for (const item of this.filteredData) {
            item.orginalCertificateDate = moment(
              item.orginalCertificateDate
            ).format("MM-DD-YYYY");
            item.expiryDate = moment(item.expiryDate).format("MM-DD-YYYY");
            if (item.program === "CPRS") {
              item.programFullName = "Certified Peer Recovery Specialist";
            } else if (item.program === "CFSS") {
              item.programFullName = "Certified Family Support Specialist";
            } else if (item.program === "CYAPSS") {
              item.programFullName =
                "Certified Young Adult Peer Support Specialist";
            }
          }
          this.isSearchData = true;
          this.isLoading = false;
          if (this.filteredData.length > 0) {
            this.isDataEmpty = false;
          } else {
            this.isDataEmpty = true;
          }
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error);
          this.isLoading = false;
        });
    } else {
      this.filteredData = [];
      this.isSearchData = false;
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
  }

  public resetData() {
    this.offset = 0;
    this.currentPage = 1;
    this.searchByFirstName = "";
    this.searchByLastName = "";
    this.isSearchData = false;
    this.filteredData = [];
    this.isLoading = true;
    this.credentialRegionSelected = [];
    this.selectedRegionListCredential = [];
    this.filterRegionGetName = [];
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public exportPeerList() {
    let programFullName = "";
    this.filterDetails.limit = null;
    this.filterDetails.offset = 0;
    this.filterDetails.firstName = this.searchByFirstName.toLowerCase();
    this.filterDetails.lastName = this.searchByLastName.toLowerCase();
    peerSpecialistsApi.getPeerSpecialist(this.filterDetails).then((data) => {
      this.downloadFullSearchList = data.peers;
      if (this.downloadFullSearchList.length > 0) {
        let peerSpecialitsSearch;
        this.json_data = [];
        this.downloadFullSearchList.forEach((data: any) => {
          this.regionListDropdown.forEach((regionList) => {
            if (data.region == regionList.id) {
              data["modifiedRegion"] = regionList.name;
            }
          });
          if (data.program === "CPRS") {
            programFullName = "Certified Peer Recovery Specialist";
          } else if (data.program === "CFSS") {
            programFullName = "Certified Family Support Specialist";
          } else if (data.program === "CYAPSS") {
            programFullName = "Certified Young Adult Peer Support Specialist";
          }
          peerSpecialitsSearch = {
            firstName: data.firstName,
            lastName: data.lastName,
            programName: programFullName,
            Region: data.modifiedRegion,
            orginalCertificateDate: data.orginalCertificateDate,
            expiryDate: data.expiryDate,
          };

          this.json_data.push(peerSpecialitsSearch);
        });
        const peerWorkbook = new Workbook();
        const worksheet = peerWorkbook.addWorksheet("Peer Specialists");
        worksheet.columns = [
          { header: "First Name", width: 25 },
          { header: "Last Name", width: 25 },
          { header: "Program Name", width: 40 },
          { header: "Region", width: 25 },
          { header: "Initial Awarded Date", width: 25 },
          { header: "Expiration Date", width: 25 },
        ];
        for (const x1 of this.json_data) {
          const x2 = Object.keys(x1);
          const temp = [];

          for (const y of x2) {
            temp.push(x1[y]);
          }
          worksheet.addRow(temp);
        }
        const fname = "Peer Specialists";
        peerWorkbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs.saveAs(blob, fname + "-" + this.dateBuilder + ".xlsx");
        });
      }
    });
  }

  public sortChanged(event: Event) {
    if (event) {
      this.offset = 0;
      this.currentPage = 1;
    }
  }

  public loadContent(page: number) {
    this.page = page;
    this.offset = (this.page - 1) * this.limit;
    this.getPeerLists();
  }

  public credentialOnClearRegion() {
    this.credentialRegionMenuList = false;
    this.isLoading = true;
    this.credentialRegionSelected = [];
    this.selectedRegionListCredential = [];
    this.filterRegionGetName = [];
    this.getPeerLists();
  }
  public credentialRegionApply() {
    this.credentialRegionMenuList = false;
    this.selectedRegionListCredential = this.credentialRegionSelected;
    this.filterRegionNameGet();
    this.getPeerLists();
  }

  public filterRegionNameGet() {
    this.filterRegionGetName = this.regionListDropdown.filter((person: any) =>
      this.selectedRegionListCredential.includes(person.id)
    );
  }
  public onClearChipRegion(id: any) {
    if (this.filterRegionGetName.length > 1) {
      const chipClearFilter = this.selectedRegionListCredential.filter(
        function (value: any) {
          return value !== id;
        }
      );
      this.credentialRegionSelected = chipClearFilter;
      this.selectedRegionListCredential = this.credentialRegionSelected;
      this.filterRegionNameGet();
      this.getPeerLists();
    } else {
      this.credentialOnClearRegion();
      this.credentialRegionMenuList = true;
    }
  }

  public credentialEnableList() {
    this.credentialRegionMenuList = true;
  }
}
